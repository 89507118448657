const STORAGE_ACCOUNT_NAME = 'bensontest0';
const STORAGE_CONTAINER_NAME = 'webapp-documentupload';

const AZURE_TENANT_ID = '1308b4fd-10ab-4788-bf1b-04250b1b01fc';
const AZURE_CLIENT_ID = 'df75b3d7-bc0a-474f-ad32-2d3916367a11';
const  AZURE_CLIENT_SECRET = '-ph8Q~EUkqPYyZjfZ-uMDbTruc2OTw9AB82smciY';

const EMAIL_REGEX_VALIDATION = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
const PASSWORD_VALIDATION_REGEX = /^(?=.*[A-Z])(?=.*\d).{8,}$/g;

export {
    STORAGE_ACCOUNT_NAME,
    STORAGE_CONTAINER_NAME,
    AZURE_TENANT_ID,
    AZURE_CLIENT_ID,
    AZURE_CLIENT_SECRET,
    EMAIL_REGEX_VALIDATION,
    PASSWORD_VALIDATION_REGEX
};