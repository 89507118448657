import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';

import { DataService } from './dataService';
import cloneDeep from 'lodash/cloneDeep';
import { rejects } from 'assert';

import { STORAGE_ACCOUNT_NAME, STORAGE_CONTAINER_NAME } from '../appConstants'; 

@Injectable()
export class HttpService implements OnInit {
  // baseUrl: string = 'http://localhost:3000/';                              // local server
  // baseUrl: any = 'https://bensonvmdev.uksouth.cloudapp.azure.com/backend/'; // dev server
    baseUrl: string = 'https://clients.bensonhealth.co.uk/backend/';                              // Prod server


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      // 'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
      'Accept': '*/*'
    })
  };

  constructor(private httpClient : HttpClient, private dataService : DataService) {
  }

  ngOnInit(){
    this.getBaseUrl();
  }

  getBaseUrl() {
    // this.baseUrl = 'https://bensonvmdev.uksouth.cloudapp.azure.com/backend/'; //dev
                // this.baseUrl = 'http://localhost:3000/';  // local
    this.baseUrl = 'https://clients.bensonhealth.co.uk/backend/';  // prod
  }

  getAzureBaseUrl () {
    return 'https://'+ STORAGE_ACCOUNT_NAME +'.blob.core.windows.net/' + STORAGE_CONTAINER_NAME;
  }

  getHttpOptions(isSecure) {
    let sessionId = this.dataService.getLoggedInUserSessionId() || "";
    let contentType= "application/json";
    if (sessionId !== "")
      contentType = contentType + '?SessionId=' + sessionId;
    if (isSecure) {
      let hders = new HttpHeaders({
        'Content-Type':  contentType,
        'Authorization': sessionStorage.getItem("authToken"),
        'Accept': '*/*'
      })
      return {
        headers: hders
      };
    } else {
      let hders = new HttpHeaders({
        'Content-Type':  contentType,
        'Accept': '*/*'
      })
      return {
        headers: hders
      };
    }
  }

  getUploadHttpOptions() {
    return {
      headers: new HttpHeaders({
        // 'Content-Type':  "multipart/form-data; boundary=arc2021poerbi",
        'Authorization': sessionStorage.getItem("authToken"),
        'Accept': '*/*',
        'enctype': 'multipart/form-data'
      })
    };
  }

  getDateTime() {
    let date = new Date();
    let month = date.getMonth() + 1;
    let dateString = '' + date.getFullYear() + '-' + month + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    return dateString;
  }

  getBrowserName() {
    let browserName = navigator.userAgent;
    return browserName;
  }

  getOS() {
    var OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0")!= -1) OSName="Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1) OSName="Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) OSName="Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) OSName="Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) OSName="Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) OSName="Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) OSName="Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac")            != -1) OSName="Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11")            != -1) OSName="UNIX";
    if (window.navigator.userAgent.indexOf("Linux")          != -1) OSName="Linux";

    return OSName;
  }

  // All GET calls here
  getProjects(isApproved: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'projectDetails/' + isApproved, this.getHttpOptions(false));
  }
  // get Sector Types
  getBuildingTypes(isApproved: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'masterBuildingTypes/' + isApproved, this.getHttpOptions(false));
  }
  // get user logins
  getUserLogins() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'userLogins', this.getHttpOptions(true));
  }

  //get approvers only
  getApproverUsersOnly() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'userLogins/approverOnly', this.getHttpOptions(true));
  }

  getPackages(isApproved: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'masterPackage/' + isApproved, this.getHttpOptions(false));
  }

  getSubPackages(isApproved: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'masterSubPackage/' + isApproved, this.getHttpOptions(false));
  }

  getUOM(isApproved: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'masterUom/' + isApproved, this.getHttpOptions(false));
  }
  getParameter(isApproved: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'masterParameters/' + isApproved, this.getHttpOptions(false));
  }

  getUserRoles() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'userRoles', this.getHttpOptions(false));
  }

  getClientRoles(orgId, type) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'clientUserRoles/organization/' + orgId + '/' + type, this.getHttpOptions(true));
  }

  getMainModules() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'mainModules', this.getHttpOptions(false));
  }

  getChildModules() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'childModules', this.getHttpOptions(false));
  }

  getUserRolePermissions(userRoleId: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'userRoles/' + userRoleId, this.getHttpOptions(false));
  }

  getClientRolePermissions(clientRoleId: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'clientUserRoles/' + clientRoleId, this.getHttpOptions(true));
  }

  getEmailSettings() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'emailconfig', this.getHttpOptions(true));
  }

  getEmbededSettings() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'embededsettings', this.getHttpOptions(true));
  }

  getgoogleAuthSettings() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'googleMicroAuthSettings/google', this.getHttpOptions(true));
  }

  getmicroAuthSettings() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'googleMicroAuthSettings/micro', this.getHttpOptions(true));
  }

  getcaptchakeySettings() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'googleMicroAuthSettings/captchakey', this.getHttpOptions(true));
  }

  getDashboardSettings() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'admindashboard', this.getHttpOptions(true));
  }

  getTermsAndConditions() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'tandc-masters', this.getHttpOptions(false));
  }

  async getAppAuthData() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'googleMicroAuthSettings/allSettings', this.getHttpOptions(false));
  }

  getReportListByWorkspace(workspaceId: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'workspace/reportList/byWorkspace/' + workspaceId, this.getHttpOptions(true));
  }

  getReportRoles(type: any, reportIds: any) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'reportRoles/byReport', {
      type,
      reportIds
    }, this.getHttpOptions(true));
  }

  getReportRolesPage(type: any, reportIds: any, offset: any, limit: any, showInActive: boolean) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'reportRoles/byReportPage', {
      type,
      reportIds,
      offset,
      limit,
      showInActive
    }, this.getHttpOptions(true));
  }

  getRolesAssignedCustomer(roleId: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'reportRoles/rolesCustomer/' + roleId, this.getHttpOptions(true));
  }

  addUpdateReportRole(reportRole: any) {
    this.getBaseUrl();
    reportRole.CreatedBy = this.dataService.getLoggedInUserId();
    if (reportRole.id !== null) {
      reportRole.UpdatedBy = this.dataService.getLoggedInUserId();
      return this.httpClient.patch(this.baseUrl + 'reportRoles/' + reportRole.id, reportRole, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'reportRoles', reportRole, this.getHttpOptions(true));
    }
  }

  makeDownloadLogEntry(obj: any) {
    this.getBaseUrl();
    obj.ActionOn = this.getDateTime();
    return this.httpClient.post(this.baseUrl + 'activitylogs', obj, this.getHttpOptions(true));
  }

  addUpdateUser(obj: any) {
    let data = cloneDeep(obj);
    data.FirstName && delete data.FirstName;
    data.LastName && delete data.LastName;
    data.ConfirmPassword && delete data.ConfirmPassword;
    data.ContactNo = data.ContactNo.toString();
    // data.Password = this.dataService.a2b(data.Password);
    this.getBaseUrl();
    if (!data.id || data.id === '') {
      delete data.id;
      data.IsActive = true;
      data.CreatedBy = this.dataService.getLoggedInUserId();
      data.CreatedUser = this.dataService.getLoggedInUserDetails().UserName;
      return this.httpClient.post(this.baseUrl + 'userLogins', data, this.getHttpOptions(true));
    } else {
      data.UpdatedBy = this.dataService.getLoggedInUserId();
      data.UpdatedUser = this.dataService.getLoggedInUserDetails().UserName;
      return this.httpClient.patch(this.baseUrl + 'userLogins/' + data.id, data, this.getHttpOptions(true));
    }
  }

  addUpdateUserRole(userRole: any, permissionsList: any) {
    let permissions = [];
    permissionsList.map((main: any) => {
      if (main.isChecked) {
        main.childModule.map((child: any) => {
          if (child.isChecked) {
            permissions.push({
              mainModuleId: main.id,
              childModuleId: child.id,
              Operation: child.moduleOperation,
              Permission: 1
            });
          }
        });
      }
    });
    userRole.permissions = permissions;
    this.getBaseUrl();
    if (!userRole.id || userRole.id === '') {
      delete userRole.id;
      return this.httpClient.post(this.baseUrl + 'userRoles', userRole, this.getHttpOptions(false));
    } else {
      userRole.UpdatedBy = this.dataService.getLoggedInUserId();
      return this.httpClient.patch(this.baseUrl + 'userRoles/' + userRole.id, userRole, this.getHttpOptions(false));
    }
  }

  addUpdateClientRole(clientRole: any, permissionsList: any) {
    let permissions = [];
    let appType = this.dataService.getAppUserType();
    permissionsList.map((main: any) => {
      if (main.isChecked) {
        main.childModule.map((child: any) => {
          if (child.isChecked) {
            permissions.push({
              mainModuleId: main.id,
              childModuleId: child.id,
              Operation: child.moduleOperation,
              Permission: 1
            });
          }
        });
      }
    });
    clientRole.permissions = permissions;
    this.getBaseUrl();
    if (!clientRole.id || clientRole.id === '') {
      delete clientRole.id;
      if (appType === "MAINADMIN") {
        clientRole.CreatedFrom = "ADMIN";
      } else {
        clientRole.CreatedFrom = "CLIENT";
      }
      return this.httpClient.post(this.baseUrl + 'clientUserRoles', clientRole, this.getHttpOptions(true));
    } else {
      if (appType === "MAINADMIN") {
        clientRole.UpdatedFrom = "ADMIN";
      } else {
        clientRole.UpdatedFrom = "CLIENT";
      }
      clientRole.UpdatedBy = this.dataService.getLoggedInUserId();
      return this.httpClient.patch(this.baseUrl + 'clientUserRoles/' + clientRole.id, clientRole, this.getHttpOptions(true));
    }
  }

  validateCred(userName: any, password: any) {
    return this.httpClient.post(this.baseUrl + 'userLogins/validateCred', {
      Email: userName,
      PassWord: password
    }, this.getHttpOptions(false));
  }


  // delete api's
  deleteProjects(id: any) {
    return this.httpClient.delete(this.baseUrl + 'projectDetails/' + id, this.getHttpOptions(false));
  }
  deletePackage(id: any) {
    return this.httpClient.delete(this.baseUrl + 'masterPackage/' + id, this.getHttpOptions(false));
  }
  deleteBuildingType(id: any) {
    return this.httpClient.delete(this.baseUrl + 'masterBuildingTypes/' + id, this.getHttpOptions(false));
  }
  deleteSubPackage(id: any) {
    return this.httpClient.delete(this.baseUrl + 'masterSubPackage/' + id, this.getHttpOptions(false));
  }
  deleteUOM(id: any) {
    return this.httpClient.delete(this.baseUrl + 'masterUom/' + id, this.getHttpOptions(false));
  }
  deleteParameter(id: any) {
    return this.httpClient.delete(this.baseUrl + 'masterParameters/' + id, this.getHttpOptions(false));
  }
  deleteUser(id: any) {
    const deletedById = this.dataService.getLoggedInUserId();
    return this.httpClient.delete(this.baseUrl + 'userLogins/' + id + '/' + deletedById, this.getHttpOptions(true));
  }
  deleteUserRole(id: any) {
    return this.httpClient.delete(this.baseUrl + 'userRoles/' + id, this.getHttpOptions(false));
  }
  deleteClientRole(id: any) {
    return this.httpClient.delete(this.baseUrl + 'clientUserRoles/' + id, this.getHttpOptions(true));
  }

  // get project measurements
  getProjectsMeasurements(projectId) {
    return this.httpClient.get(this.baseUrl + 'projectDetails/measurements/' + projectId, this.getHttpOptions(false));
  }

  validateCaptchaResponse(token) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientProfiles/validateCaptchaResponse', {token}, this.getHttpOptions(false));
  }

  // flex bi
  registerUserByThirdParty(data) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientProfiles/', data, this.getHttpOptions(false));
  }
  validateUserCred(userName: any, password: any) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientProfiles/validateCred', {
      UserName: userName,
      PassWord: password
    }, this.getHttpOptions(false));
  }
  registerUser(obj) {
    let appType = this.dataService.getAppUserType();
    let data = cloneDeep(obj);
    data.FirstName && delete data.FirstName;
    data.LastName && delete data.LastName;
    data.ConfirmPassword && delete data.ConfirmPassword;
    data.ContactNo = data.ContactNo ? data.ContactNo.toString() : "";
    data.CreatedBy = this.dataService.getLoggedInUserId() ? this.dataService.getLoggedInUserId() : 0;
    // data.Password = this.dataService.a2b(data.Password);
    this.getBaseUrl();
    if (!data.id || data.id === '') {
      delete data.id;
      if (appType === "MAINADMIN") {
        data.CreatedFrom = "ADMIN";
      } else {
        data.CreatedFrom = "CLIENT";
      }
      return this.httpClient.post(this.baseUrl + 'clientProfiles/register', data, this.getHttpOptions(false));
    } else {
      if (appType === "MAINADMIN") {
        data.UpdatedFrom = "ADMIN";
      } else {
        data.UpdatedFrom = "CLIENT";
      }
      data.UpdatedBy = this.dataService.getLoggedInUserId();
      return this.httpClient.patch(this.baseUrl + 'clientProfiles/register/' + data.id, data, this.getHttpOptions(true));
    }
  }
  getCustomerList(orgId: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'clientProfiles/organization/' + orgId, this.getHttpOptions(true));
  }
  getCustomerListPage(orgId: any, offset: any, limit: any, showInActive) {
    this.getBaseUrl();
    let url = "clientProfiles/organization/page?filter[offset]=" + offset + "&filter[limit]=" + limit + "&filter[where][orgId]=" + orgId + "&filter[where][showInActive]=" + showInActive;
    return this.httpClient.get(this.baseUrl + url, this.getHttpOptions(true));
  }
  getAllCustomerList() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'clientProfiles', this.getHttpOptions(true));
  }
  getAllCustomerListPage(offset, limit, showInActive) {
    this.getBaseUrl();
    let url = "clientProfiles/page?filter[offset]=" + offset + "&filter[limit]=" + limit + "&filter[where][showInActive]=" + showInActive;
    return this.httpClient.get(this.baseUrl + url, this.getHttpOptions(true));
  }
  getorganizationList() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'organizationDetails/', this.getHttpOptions(true));
  }
  getorganizationListPage(offset, limit, showInActive) {
    this.getBaseUrl();
    let url = "organizationDetails/page?filter[offset]=" + offset + "&filter[limit]=" + limit + "&filter[where][showInActive]=" + showInActive;
    return this.httpClient.get(this.baseUrl + url, this.getHttpOptions(true));
  }
  getorganizationTypesList() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'orgTypes', this.getHttpOptions(true));
  }
  getBusinessUnitsLists() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'businessunits/', this.getHttpOptions(true));
  }

  getDocumentList(type) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'docmasters/' + type, this.getHttpOptions(true));
  }
  getDocumentListByUserAndOrg(orgId, userId, type) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'docmastersByUser/' + orgId + '/' + userId + '/' + type, this.getHttpOptions(true));
  }
  getDocumentListPage(type, offset, limit, showInActive, orgId) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'docmastersPage/' + type + '/' + offset + '/' + limit + '/' + showInActive + '/' + orgId, this.getHttpOptions(true));
  }
  getDocumentListByUserAndOrgPage(orgId, userId, type, offset, limit, showInActive) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'docmastersByUserPage/' + orgId + '/' + userId + '/' + type + '/' + offset + '/' + limit + '/' + showInActive, this.getHttpOptions(true));
  }

  getReportGroups() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'reportgroups/', this.getHttpOptions(true));
  }
  deleteCustomer(id) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + 'clientProfiles/' + id, this.getHttpOptions(true));
  }
  deleteReportRole(id) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + 'reportRoles/' + id, this.getHttpOptions(true));
  }
  deleteOrganization(id) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + 'organizationDetails/' + id, this.getHttpOptions(true));
  }
  deleteOrganizationType(id) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + 'orgTypes/' + id, this.getHttpOptions(true));
  }
  reActivateCustomer(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'clientProfiles/reactivate/' + id, this.getHttpOptions(true));
  }
  reActivateOrganization(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'organizationDetails/reactivate/' + id, this.getHttpOptions(true));
  }
  reActivateOrganizationType(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'orgTypes/reactivate/' + id, this.getHttpOptions(true));
  }
  reActivateDocuments(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'docmasters/reactivate/' + id, this.getHttpOptions(true));
  }
  reActivateReportRole(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'reportRoles/reactivate/' + id, this.getHttpOptions(true));
  }
  getConvertedPass(id, type) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'clientProfiles/convertedPass/' + id + "/"+ type, this.getHttpOptions(true));
  }
  sendResetPasswordLink(emailId, type) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientProfiles/resetPass', {
      emailId,
      type
    }, this.getHttpOptions(false));
  }
  checkForResetPassword(obj, type) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientProfiles/checkResetPassLink', {
      obj,
      type
    }, this.getHttpOptions(false));
  }
  checkForFirstLogin(uniqueID, type) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientProfiles/checkFirstLoginLink', {
      uniqueID,
      type
    }, this.getHttpOptions(false));
  }
  checkForAdminUserFirstLogin(uniqueID, type) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'userLogins/checkFirstLoginLink', {
      uniqueID,
      type
    }, this.getHttpOptions(false));
  }
  updateUserFirstLoginPassword(obj: any) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientProfiles/updateUserFirstLoginPassword', obj, this.getHttpOptions(false));
  }
  updateAdminUserFirstLoginPassword(obj: any) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'userLogins/updateAdminUserFirstLoginPassword', obj, this.getHttpOptions(false));
  }
  changePassword(obj) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientProfiles/changePass', {
      obj
    }, this.getHttpOptions(true));
  }
  getworkspaceList() {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'workspace', this.getHttpOptions(true));
  }

  getworkspaceListPage(offset, limit, showInActive) {
    this.getBaseUrl();
    let url = "workspace/page?filter[offset]=" + offset + "&filter[limit]=" + limit + "&filter[where][showInActive]=" + showInActive;
    return this.httpClient.get(this.baseUrl + url, this.getHttpOptions(true));
  }
  getClientWorkspace(orgId) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'workspace/client/' + orgId, this.getHttpOptions(true));
  }

  addUpdateReportBundles(reportBundle) {
    this.getBaseUrl();
    reportBundle.CreatedBy = this.dataService.getLoggedInUserId();
    if (reportBundle.id !== null) {
      reportBundle.UpdatedBy = this.dataService.getLoggedInUserId();
      return this.httpClient.patch(this.baseUrl + 'reportBundles/' + reportBundle.id, reportBundle, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'reportBundles/', reportBundle, this.getHttpOptions(true));
    }
  }
  
  getReportBundleListPage(offset, limit, showInActive) {
    this.getBaseUrl();
    let url = "reportBundles/page?filter[offset]=" + offset + "&filter[limit]=" + limit + "&filter[where][showInActive]=" + showInActive;
    return this.httpClient.get(this.baseUrl + url, this.getHttpOptions(true));
  }

  getReportBundleListPageById(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + `reportBundles/` + id, this.getHttpOptions(true));
  }

  deleteReportBundleListPageById(id) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + `reportBundles/` + id, this.getHttpOptions(true));
  }

  reActivateReportBundle(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'reportBundles/reactivate/' + id, this.getHttpOptions(true));
  }

  addUpdateWorkspace(workSpace) {
    this.getBaseUrl();
    workSpace.CreatedBy = this.dataService.getLoggedInUserId();
    if (workSpace.id !== null) {
      workSpace.UpdatedBy = this.dataService.getLoggedInUserId();
      return this.httpClient.patch(this.baseUrl + 'workspace/' + workSpace.id, workSpace, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'workspace', workSpace, this.getHttpOptions(true));
    }
  }
  addUpdateReportGroup(reportGroup) {
    this.getBaseUrl();
    reportGroup.CreatedBy = this.dataService.getLoggedInUserId();
    if (reportGroup.id !== null) {
      return this.httpClient.patch(this.baseUrl + 'reportgroups/' + reportGroup.id, reportGroup, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'reportgroups/', reportGroup, this.getHttpOptions(true));
    }
  }
  addUpdateOrganization(org) {
    this.getBaseUrl();
    // delete org.FirstName;
    // delete org.LastName;
    org.ContactNumber = org.ContactNumber.toString();
    org.CreatedBy = this.dataService.getLoggedInUserId();
    if (org.id !== null) {
      org.UpdatedBy = this.dataService.getLoggedInUserId();
      return this.httpClient.patch(this.baseUrl + 'organizationDetails/' + org.id, org, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'organizationDetails', org, this.getHttpOptions(true));
    }
  }
  addUpdateOrganizationType(orgType) {
    this.getBaseUrl();
    orgType.CreatedBy = this.dataService.getLoggedInUserId();
    if (orgType.id !== null) {
      orgType.UpdatedBy = this.dataService.getLoggedInUserId();
      return this.httpClient.patch(this.baseUrl + 'orgTypes/' + orgType.id, orgType, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'orgTypes', orgType, this.getHttpOptions(true));
    }
  }

  getUploadAccessToken(document, blobName) {
    const orgId = document.id_FkOrganization;
    // get AccessToekn 
    return this.httpClient.post(this.baseUrl + 'docmasters/generateUploadToken', {
      orgId: orgId,
      blobName
    }, this.getHttpOptions(true))
  }

  deleteDocument(doc) {
    this.getBaseUrl();
    return new Promise((resolve, reject) => {
      return this.httpClient.get(this.baseUrl + 'docmasters/generateUploadToken/' + doc.id_FkOrganization, this.getHttpOptions(true)).subscribe((res: any) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${res.accessToken}`,
          "x-ms-version": "2017-11-09"
        });
        return this.httpClient.delete(doc.DocumentURL, { headers }).subscribe((res: any) => {
          return this.httpClient.delete(this.baseUrl + 'docmasters/' + doc.id, this.getHttpOptions(true)).subscribe((res: any)=> {
            if (res && res.status === 200) {
              resolve({
                status: 200
              })
            } else {
              reject({
                status: 500
              })
            }
          }, (er: any) => {
            reject({
              status: 500
            })
          });
        });
      });
    });
  }

  downloadBlob(docUrl, orgId) {
    return new Promise((resolve, reject) => {
      return this.httpClient.get(this.baseUrl + 'docmasters/generateUploadToken/' + orgId, this.getHttpOptions(true)).subscribe((res: any) => {
        
        // Set up the headers with the access token
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${res.accessToken}`,
          "x-ms-version": "2017-11-09",
          // 'Accept': 'application/octet-stream',
          // 'x-ms-blob-type': 'BlockBlob',
          'Accept': '*/*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
          // 'Content-Type': 'application/json',
        });

        // Make the HTTP request
        return this.httpClient.get(docUrl, { headers: headers, responseType: 'blob', observe: 'response' }).subscribe((blobData: any) => {
          const contentDispositionHeader = blobData.headers.get('Content-Disposition');
        
          // // Create a Blob from the array buffer
          const blob = new Blob([blobData.body], { type: blobData?.body?.type || '' });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = docUrl.substring(docUrl.lastIndexOf('/')+ 1, docUrl.length); // Replace with your desired file name
          link.click();

          if (blobData) {
            resolve({
              status: 200
            })
          } else {
            reject({
              status: 500
            })
          }
        }, (er: any) => {
          reject({
            status: 500,
            error: er
          })
        });
      });
    });
  }

  uploadDocFileToAzure(document, accessToken, OrgCode) {
    return new Promise(async(resolve, reject) => {
      var formData = new FormData();
      await formData.append("files", document.DocumentFile, document.DocumentFile.name);
      // formData.append('Content-Type', document.DocumentFile.type);

      const headers = new HttpHeaders({
        'Accept': '*/*',
        'Authorization': 'Bearer ' + accessToken || "", // Get the access token using your Service Principal credentials
        "x-ms-version": "2017-11-09",
        'x-ms-blob-type': 'BlockBlob',
        // "Content-Type": 'multipart/form-data',
        // 'x-ms-blob-content-type': document.DocumentFile.type,
        // 'x-ms-blob-content-disposition': `attachment; filename="${document.DocumentFile.name}"`,
        'x-ms-date': new Date().toUTCString(),
      });

      const apiUrl = this.getAzureBaseUrl() + "/" + OrgCode + "/" + document.DocumentFile.name;
      return this.httpClient.put(apiUrl, formData, { headers: headers, responseType: 'json'}).subscribe((e: any) => {
        console.log(e);
        resolve({
          status: 200,
          fileUrl: this.getAzureBaseUrl()+ "/" + OrgCode + "/" + document.DocumentFile.name
        })
      }, (er: any) => {
        console.log(er);
        reject({
          status: 500,
          fileUrl: null
        })
      });
    });
  }

  getBlobListFromAzure(accessToken) {
    const url = this.getAzureBaseUrl() + '?restype=container&comp=list';

    // Set up the headers with the access token
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });

    // Make the HTTP request
    return this.httpClient.get(url, { headers });
  }

  addUpdateDocument(document) {
    this.getBaseUrl();
    document.CreatedBy = this.dataService.getLoggedInUserId();
    const orgId = document.id_FkOrganization;
    if (document.DocumentType.toUpperCase() === "WEB") {
      if (document.id !== null) {
        document.UpdatedBy = this.dataService.getLoggedInUserId();
        return this.httpClient.patch(this.baseUrl + 'docmasters/' + document.id, document, this.getHttpOptions(true));
      } else {
        return this.httpClient.post(this.baseUrl + 'docmasters', document, this.getHttpOptions(true));
      }
    } else {
      var formData = new FormData();
      formData.append("files", document.DocumentFile, document.DocumentFile.name);
      
      return this.httpClient.post(this.baseUrl + 'docmasters/doc/' + orgId + "/", formData, this.getUploadHttpOptions());
    }
  }
  addDocumentWithLink(document){
    delete document.DocumentFile;
    document.CreatedBy = this.dataService.getLoggedInUserId();
    document.UpdatedBy = this.dataService.getLoggedInUserId();
    return this.httpClient.post(this.baseUrl + 'docmasters', document, this.getHttpOptions(true));
  }

  getMachineIpAddress() {
    return this.httpClient.get("http://api.ipify.org/?format=json");
  }

  storeUserSession(obj: any) {
    obj.OS = this.getBrowserName();
    obj.Browser = this.getOS();
    return this.httpClient.post(this.baseUrl + 'usersessions/', obj, this.getHttpOptions(true));
  }
  updateLogoutTime(appUserType: any) {
    let obj = {
      UserID: this.dataService.getLoggedInUserId(),
      UserSessionID: this.dataService.getLoggedInUserSessionId(),
      appUserType
    };
    return this.httpClient.post(this.baseUrl + 'usersessions/logout/', obj, this.getHttpOptions(false));
  }
  updateDocument(document) {
    document.UpdatedBy = this.dataService.getLoggedInUserId();
    return this.httpClient.patch(this.baseUrl + 'docmasters/' + document.id, document, this.getHttpOptions(true));
  }
  getWorkspaceDetails(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'workspace/' + id, this.getHttpOptions(true));
  }
  deleteWorkspace(id) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + 'workspace/' + id, this.getHttpOptions(true));
  }
  reActivateWorkspace(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'workspace/reactivate/' + id, this.getHttpOptions(true));
  }
  reActivateReport(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'report/reactivate/' + id, this.getHttpOptions(true));
  }
  getAllReportsListByCustomerAndWorkspace(customerId: any, appUserType: any) {
    this.getBaseUrl();
    if (appUserType === "MAINADMIN") {
      return this.httpClient.post(this.baseUrl + 'clientReportAllocation/reportList', {
        customerId: customerId || ""
      }, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'clientReportAllocation/clientReportList', {
        customerId: customerId || ""
      }, this.getHttpOptions(true));
    }
  }
  getAllDocumentListByCustomer(customerId: any, appUserType: any) {
    this.getBaseUrl();
    if (appUserType === "MAINADMIN") {
      return this.httpClient.post(this.baseUrl + 'clientDocumentAllocation/documentList', {
        customerId: customerId || ""
      }, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'clientDocumentAllocation/clientDocumentList', {
        customerId: customerId || ""
      }, this.getHttpOptions(true));
    }
  }
  allocateReportsToClient(obj: any) {
    obj.CreatedBy = this.dataService.getLoggedInUserId();
    obj.AllocatedBy = this.dataService.getLoggedInUserDetails().UserRole;
    obj.CreatedFrom = "CLIENT";
    if (this.dataService.getAppUserType() === "MAINADMIN")
      obj.CreatedFrom = "ADMIN";
    return this.httpClient.post(this.baseUrl + 'clientReportAllocation', obj, this.getHttpOptions(true));
  }
  allocateDocumentsToClient(obj: any) {
    obj.CreatedBy = this.dataService.getLoggedInUserId();
    obj.AllocatedBy = this.dataService.getLoggedInUserDetails().UserRole;
    obj.CreatedFrom = "CLIENT";
    if (this.dataService.getAppUserType() === "MAINADMIN")
      obj.CreatedFrom = "ADMIN";
    return this.httpClient.post(this.baseUrl + 'clientDocumentAllocation', obj, this.getHttpOptions(true));
  }
  getReportsByWorkspaceIds(selectedWorkspace, appUserType, selectedOrgId, rgrpList) {
    if (appUserType === 'MAINADMIN') {
      return this.httpClient.post(this.baseUrl + 'workspace/reportList/', {selectedItems: selectedWorkspace}, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'workspace/reportList/byOrg/' + selectedOrgId, {
        selectedItems: selectedWorkspace,
        reportGroupList: rgrpList
      }, this.getHttpOptions(true));
    }
  }


  checkWorkspaceAllocation(workspaceId: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'workspace/isAllocated/' + workspaceId, this.getHttpOptions(true));
  }
  checkDocumentAllocation(docId: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'docmasters/isAllocated/' + docId, this.getHttpOptions(true));
  }
  getAllocatedReportListsWithCustomer(orgId: any, type, pagination: any) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientReportAllocation/reportListWithCustomer/' + orgId + '/' + type, pagination, this.getHttpOptions(true));
  }
  getAllocatedDocumentListsWithCustomer(orgId: any, type) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'clientDocumentAllocation/documentListWithCustomer/' + orgId + '/' + type, this.getHttpOptions(true));
  }
  deleteAllocationReports(customerId: any, type: any) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + 'clientReportAllocation/' + customerId + "/" + type, this.getHttpOptions(true));
  }
  deleteAllocationDocument(customerId: any, type: any) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + 'clientDocumentAllocation/' + customerId + "/" + type, this.getHttpOptions(true));
  }
  checkReportAllocation(reportId: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'clientReportAllocation/isAllocatedReport/' + reportId, this.getHttpOptions(true));
  }
  getWebLinkDetails(webLinkId: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'docmasters/webLinkDetails/' + webLinkId, this.getHttpOptions(true));
  }
  deleteAllocatedReport(reportId: any) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + 'clientReportAllocation/deleteReport/' + reportId, this.getHttpOptions(true));
  }
  updateReport(report: any) {
    this.getBaseUrl();
    report.UpdatedBy = this.dataService.getLoggedInUserId();
    return this.httpClient.post(this.baseUrl + 'clientReportAllocation/updateReport', report, this.getHttpOptions(true));
  }
  setAsDefault(report: any, customer: any) {
    this.getBaseUrl();
    report.UpdatedBy = this.dataService.getLoggedInUserId();
    return this.httpClient.post(this.baseUrl + 'clientReportAllocation/markDefault', {
      report,
      customer
    }, this.getHttpOptions(true));
  }
  getEmbedReport(workspaceId, reportId, userId) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientReportAllocation/getEmbedReport', {
      workspaceId,
      reportId,
      userId
    }, this.getHttpOptions(true));
  }
  getARCDashboard(workspaceId, reportId, datasetId, userId) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientReportAllocation/getArcDashboard', {
      workspaceId,
      reportId,
      datasetId,
      userId
    }, this.getHttpOptions(true));
  }
  updateEmailSettings(emailSettings: any) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'emailconfig', {
      id: emailSettings.id,
      EmailID: emailSettings.EmailID,
      EmailPass: emailSettings.EmailPass,
      CreatedBy: this.dataService.getLoggedInUserId()
    }, this.getHttpOptions(true));
  }

  updateEmbedeSettings(embedSettings: any) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'embededsettings', {
      id: embedSettings.id,
      EmbedMethod: embedSettings.EmbedMethod,
      ApplicationId: embedSettings.ApplicationId,
      ClientSecret: embedSettings.ClientSecret,
      ClientId: embedSettings.ClientId,
      TenantId: embedSettings.TenantId,
      Account: embedSettings.Account ? embedSettings.Account : '',
      AccountPass: embedSettings.AccountPass ? embedSettings.AccountPass : '',
      CreatedBy: this.dataService.getLoggedInUserId(),
      ClientSecretExpireDate: embedSettings.ClientSecretExpireDate
    }, this.getHttpOptions(true));
  }

  updateGoogleMicroAuthSettings(googleAuthSettings: any) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'googleMicroAuthSettings', {
      id: googleAuthSettings.id,
      RedirectURL: googleAuthSettings.RedirectURL,
      ClientId: googleAuthSettings.ClientId,
      AuthenticationFor: googleAuthSettings.AuthenticationFor,
      ClientSecret: googleAuthSettings?.ClientSecret || null,
      CreatedBy: this.dataService.getLoggedInUserId()
    }, this.getHttpOptions(true));
  }

  updateDashboardSettings(dashboardSettings: any) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'admindashboard', {
      id: dashboardSettings.id,
      DashboardName: dashboardSettings.DashboardName,
      ReportID: dashboardSettings.ReportID,
      WorkspaceID: dashboardSettings.WorkspaceID,
      DatasetID: dashboardSettings.DatasetID,
      IsActive: true
    }, this.getHttpOptions(true));
  }

  updateTermsAndConditions(termsAndConditions: any) {
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'tandc-masters', {
      id: termsAndConditions.id,
      TermsConditionsHTML: termsAndConditions.TermsConditionsHTML,
      IsActive: true,
      CreatedBy: this.dataService.getLoggedInUserId(),
      UpdatedBy: this.dataService.getLoggedInUserId()
    }, this.getHttpOptions(true));
  }

  acceptTermsAndConditions(userId: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'tandc-masters/acceptTerms/' + userId, this.getHttpOptions(false));
  }
  rejectTermsAndConditions(userId: any) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'tandc-masters/rejectTerms/' + userId, this.getHttpOptions(false));
  }

  addSupportTicket(SupportTicket){
    this.getBaseUrl();
    return this.httpClient.post(this.baseUrl + 'clientsupportticket', SupportTicket, this.getHttpOptions(true));

  }

  getFaqQuestionAndAnswer(isActive:any){
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl+'faqs/'+ isActive, this.getHttpOptions(true))
  }

  
  getResourcesTutorialsAndGuides(resourceType:any,isActive:any){
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl+'helpresources/'+ resourceType + "/" + isActive, this.getHttpOptions(true))
  }

  getTeamProfiles(offset, limit, showInActive) {
    this.getBaseUrl();
    let url = `teamProfiles/${offset}/${limit}/${showInActive}`;
    return this.httpClient.get(`${this.baseUrl}${url}/ALL`, this.getHttpOptions(true));

  }

  getTeamProfileListPage(offset, limit, showInActive, orgId){
    this.getBaseUrl();
    let url = `teamProfiles/${offset}/${limit}/${showInActive}`;
    return this.httpClient.get(`${this.baseUrl}${url}/${orgId}`, this.getHttpOptions(true));
  }

  getTeamTypesList(){
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + `teamTypes`, this.getHttpOptions(true));

  }

  addUpdateTeamProfile(teamProfile){
    this.getBaseUrl();
    teamProfile.CreatedBy = this.dataService.getLoggedInUserId();
    if (teamProfile.id !== null) {
      teamProfile.UpdatedBy = this.dataService.getLoggedInUserId();
      return this.httpClient.patch(this.baseUrl + 'teamProfiles/' + teamProfile.id, teamProfile, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'teamProfiles/', teamProfile, this.getHttpOptions(true));
    }
  }

  getTeamProfileListPageById(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + `teamProfiles/` + id, this.getHttpOptions(true));
  }


  getDepartmentTypeList(orgId) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'departmentProfiles/'+ orgId , this.getHttpOptions(true));
  }

  reActivateTeamProfile(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'teamProfiles/reactivate/' + id, this.getHttpOptions(true));
  }
  deleteTeamProfileById(id) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + `teamProfiles/` + id, this.getHttpOptions(true));
  }

  // get Skills

  getclinicalSkillsList(){
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + `clinicalSkills`, this.getHttpOptions(true));

  }

  // Get Staff Brands

  getstaffBandsList(){
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + `staffBands`, this.getHttpOptions(true));

  }

  // get staff profile  list

  getStaffProfiles(offset, limit, showInActive) {
    this.getBaseUrl();
    let url = `staffProfiles/${offset}/${limit}/${showInActive}`;
    return this.httpClient.get(`${this.baseUrl}${url}/ALL`, this.getHttpOptions(true));

  }

  getStaffProfileListPage(offset, limit, showInActive, orgId){
    this.getBaseUrl();
    let url = `staffProfiles/${offset}/${limit}/${showInActive}`;
    return this.httpClient.get(`${this.baseUrl}${url}/${orgId}`, this.getHttpOptions(true));
  }

  // addUpdate staff profile

  addUpdateStaffProfile(staffProfile){
    this.getBaseUrl();
    staffProfile.CreatedBy = this.dataService.getLoggedInUserId();
    if (staffProfile.id) {
      staffProfile.UpdatedBy = this.dataService.getLoggedInUserId();
      return this.httpClient.patch(this.baseUrl + 'staffProfiles/' + staffProfile.id, staffProfile, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'staffProfiles/', staffProfile, this.getHttpOptions(true));
    }
  }

  //get data by id

  getStaffProfileListPageById(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + `staffProfiles/` + id, this.getHttpOptions(true));
  }

  // delete staff profile

  deleteStaffProfileById(id) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + `staffProfiles/` + id, this.getHttpOptions(true));
  }

  // reactivate staff profile

  reActivateStaffProfile(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'staffProfiles/reactivate/' + id, this.getHttpOptions(true));
  }
  // get All care pathway 

  getCarepathwayList(offset, limit, showInActive) {
    this.getBaseUrl();
    let url = `cpprofiles/${offset}/${limit}/${showInActive}`;
    return this.httpClient.get(`${this.baseUrl}${url}/ALL`, this.getHttpOptions(true));

  }

  // add and Update care pathway

  addUpdateCarePathway(carepathway){
    this.getBaseUrl();
    carepathway.CreatedBy = this.dataService.getLoggedInUserId();
    if (carepathway.id) {
      carepathway.UpdatedBy = this.dataService.getLoggedInUserId();
      return this.httpClient.patch(this.baseUrl + 'cpprofiles/' + carepathway.id, carepathway, this.getHttpOptions(true));
    } else {
      return this.httpClient.post(this.baseUrl + 'cpprofiles/', carepathway, this.getHttpOptions(true));
    }
  }

 // get care pathway by organization id 

  getCarepathwayListByOrgId(offset, limit, showInActive, orgId){
    this.getBaseUrl();
    let url = `cpprofiles/${offset}/${limit}/${showInActive}`;
    return this.httpClient.get(`${this.baseUrl}${url}/${orgId}`, this.getHttpOptions(true));
  }

  // get population type 

  getPopulationType(){
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + `populationType`, this.getHttpOptions(true));

  }

  // get popultion group type 

  getPopulationGroupTypeList(orgId,popTypeId){
    this.getBaseUrl();
    let populationGroup = `populationGroups/${orgId}/${popTypeId}`
    return this.httpClient.get(`${this.baseUrl}${populationGroup}` , this.getHttpOptions(true));
  }

  // reactivate care pathway 

  reActivateCarePathway(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'cpprofiles/reactivate/' + id, this.getHttpOptions(true));
  }

  // soft and hard delete care pathway

  softAndHardDelateCarePathway(id) {
    this.getBaseUrl();
    return this.httpClient.delete(this.baseUrl + 'cpprofiles/' + id, this.getHttpOptions(true));
  }

  // get Individual Care pathway

  getCarePathwayById(id) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + `cpprofiles/` + id, this.getHttpOptions(true));
  }

  // get Care pathway type

  getCarePathwayType(){
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + `cpTypes`, this.getHttpOptions(true));
  }

  // get all activity profile list 

  getActivityProfileList(offset, limit, showInActive) {
    this.getBaseUrl();
    let url = `activityProfile/${offset}/${limit}/${showInActive}`;
    return this.httpClient.get(`${this.baseUrl}${url}/ALL`, this.getHttpOptions(true));

  }

  // get all activity profile by org id 

  getActivityProfileListByOrgId(offset, limit, showInActive, orgId){
    this.getBaseUrl();
    let url = `activityProfile/${offset}/${limit}/${showInActive}`;
    return this.httpClient.get(`${this.baseUrl}${url}/${orgId}`, this.getHttpOptions(true));
  }

  // get venue type

  getVenueTypeList(){
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + `venueTypes`, this.getHttpOptions(true));
  }

  // get activity type

  getactivityTypesList(){
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + `activityTypes`, this.getHttpOptions(true));
  }

  // get care pathway list 

  getCarePathwayList(orgId) {
    this.getBaseUrl();
    return this.httpClient.get(this.baseUrl + 'cpprofiles/'+ orgId , this.getHttpOptions(true));
  }

    // add and Update activityProfile

    addUpdateActivityProfile(activityProfile){
      this.getBaseUrl();
      activityProfile.CreatedBy = this.dataService.getLoggedInUserId();
      if (activityProfile.id) {
        activityProfile.UpdatedBy = this.dataService.getLoggedInUserId();
        return this.httpClient.patch(this.baseUrl + 'activityProfile/' + activityProfile.id, activityProfile, this.getHttpOptions(true));
      } else {
        return this.httpClient.post(this.baseUrl + 'activityProfile/', activityProfile, this.getHttpOptions(true));
      }
    }

  // reactivate Activity profile 

    reActivateActivityProfile(id) {
      this.getBaseUrl();
      return this.httpClient.get(this.baseUrl + 'activityProfile/reactivate/' + id, this.getHttpOptions(true));
    }
  
    // soft and hard delete Activity profile
  
    softAndHardDelateActivityProfile(id) {
      this.getBaseUrl();
      return this.httpClient.delete(this.baseUrl + 'activityProfile/' + id, this.getHttpOptions(true));
    }

    // get activity profile by id

    getActivityProfileById(id) {
      this.getBaseUrl();
      return this.httpClient.get(this.baseUrl + `activityProfile/` + id, this.getHttpOptions(true));
    }


    //  Refresh dataset

    getRefreshdataSet() {
      this.getBaseUrl();
      return this.httpClient.get(this.baseUrl + `getPowerBiRefreshStatus/`, this.getHttpOptions(true));
    }

    //  refresh all the Dataset
     
    getRefreshDataSetForAll() {
      this.getBaseUrl();
      return this.httpClient.get(this.baseUrl + `refreshDataSetForAll/`, this.getHttpOptions(true));
    }


  
  




}
