import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-logout-confirmation-prompt',
  templateUrl: 'logout-confirmation-prompt.component.html',
  styleUrls: ['logout-confirmation-prompt.component.scss'],
})
export class DialogLogoutConfirmationPromptComponent {

  @Input() sessionTimeout: any;

  constructor(protected ref: NbDialogRef<DialogLogoutConfirmationPromptComponent>) {}

  cancel() {
    this.ref.close('NO');
  }

  submit() {
    this.ref.close('YES');
  }

  confirm(){
    this.ref.close('OK');
  }
}
