<nb-card *ngIf="!sessionTimeout">
  <nb-card-header>Logout Confirmation</nb-card-header>
  <nb-card-body>
    <label>Are you sure to Logout from the system?</label>
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton status="danger" (click)="cancel()">No</button>
    <button nbButton status="success" (click)="submit()">Yes</button>
  </nb-card-footer>
</nb-card>
<nb-card *ngIf="sessionTimeout">
  <nb-card-header>Session Timeout</nb-card-header>
  <nb-card-body>
    <label>Your session has expired, please login again.</label>
  </nb-card-body>
  <nb-card-footer>
    <!-- <button class="cancel" nbButton status="danger" (click)="cancel()">No</button> -->
    <button nbButton status="success" (click)="confirm()">Ok</button>
  </nb-card-footer>
</nb-card>