import { Injectable } from '@angular/core';
import { DataService } from './dataService';
import { HttpService } from './httpservice';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { DialogLogoutConfirmationPromptComponent } from '../@theme/components/header/logout-confirmation/logout-confirmation-prompt.component';

@Injectable()
export class sessionTimeoutService {

  constructor(private dataService:DataService,private httpservice:HttpService,private _router:Router,private dialogService:NbDialogService) {
  }
  
  inactivityTimeout: any;
  INACTIVITY_TIME = 1 * 1800 * 1000; // 15 Minutes
  eventListeners: { [key: string]: (event: Event) => void } = {};


  checkSession(){
    const sessionTimeout = sessionStorage.getItem('sessiontimeout')
    if(sessionTimeout ==="true"){
      this.logout()
    }else{
      this.userActivityTraker()
    }
  }
  // check user activity
  userActivityTraker(){
    if(sessionStorage.getItem('authToken')){
      this.initializeInactivityTracking();
    }
  }
 // initialize traking
 initializeInactivityTracking(): void {
    if (sessionStorage.getItem('authToken')) {
      this.resetInactivityTimeout();
      // Define event listener functions
      const events = ['click', 'mousemove', 'keydown','wheel'];
      events.forEach(event => {
        this.eventListeners[event] = () => this.resetInactivityTimeout();
        window.addEventListener(event, this.eventListeners[event]);
      });
    }

  }

  // Remove event listeners
  removeInactivityTracking(): void {
    Object.keys(this.eventListeners).forEach(event => {
      if (this.eventListeners[event]) {
        window.removeEventListener(event, this.eventListeners[event]);
      }
    });

    // Clear timeout
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
      this.inactivityTimeout = null;
    }
  }


  // Reset inactivity timer
   resetInactivityTimeout(): void {
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
    }
    this.inactivityTimeout = setTimeout(() => {
      this.openLogoutDialog()
      this.removeInactivityTracking()
    }, this.INACTIVITY_TIME);
  }

  // Logout the user
  logout(): void {
    if (this.dataService.getAppUserType()=== "CLIENT") {
      if (window.location.href.indexOf("?") > -1) {
        this._router.navigate([
          "/login/user" +
            window.location.href.substr(
              window.location.href.indexOf("?"),
              window.location.href.length
            ),
        ]);
      } else {
        this._router.navigate(["/login/user"]);
        
      }
    } else {
      this._router.navigate(["/login/admin"]);
    }
    // update the logout time of the user session
    this.httpservice
      .updateLogoutTime(this.dataService.getAppUserType())
      .subscribe(
        () => {
          localStorage.removeItem("loggedInUserSessionId");
          localStorage.removeItem("loggedInUserId");
          localStorage.removeItem("loggedInUserPermissions");
          localStorage.removeItem("loggedInUserDetails");
          sessionStorage.removeItem("authToken");
          sessionStorage.removeItem("appUserType");
          sessionStorage.removeItem("clientOrganization");
          sessionStorage.removeItem("sessiontimeout");
        },
        () => {
          localStorage.removeItem("loggedInUserSessionId");
          localStorage.removeItem("loggedInUserId");
          localStorage.removeItem("loggedInUserPermissions");
          localStorage.removeItem("loggedInUserDetails");
          sessionStorage.removeItem("authToken");
          sessionStorage.removeItem("appUserType");
          sessionStorage.removeItem("clientOrganization");
          sessionStorage.removeItem("sessiontimeout");
        }
      );
  }


  openLogoutDialog() {
    sessionStorage.setItem("sessiontimeout", "true");
    this.dialogService
      .open(DialogLogoutConfirmationPromptComponent, {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        context: {
          sessionTimeout: true
        }
      })
      .onClose.subscribe((param: any) => {
        if (param === "OK") {
          this.logout();
        }
      });
  }
}