import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
declare var $:any;

@Injectable()
export class DataService {

  organisation: any = {};
    constructor() {
  }
  user: any = {}
  orgId: any = null;
  selectedUserToMove: any = [];
  loggedInUserId: any = null;
  loggedInUserSessionId: any = null;
  loggedInUserDetails: any = null;
  loggedInUserPermissions: any = null;
  appUserType: any = "MAINADMIN";
  appAuthData: any = null;

  setOrganisationData(data) {
    this.organisation = data;
    sessionStorage.setItem("clientOrganization", JSON.stringify(this.organisation));
  };
  getOrganisationData() {
    this.organisation = JSON.parse(sessionStorage.getItem("clientOrganization"));
    return (this.organisation ? this.organisation : null)
  }

  setAppUserType(type) {
    this.appUserType = type;
    sessionStorage.setItem("appUserType", this.appUserType);
  };
  getAppUserType() {
    this.appUserType = sessionStorage.getItem("appUserType");
    return (this.appUserType ? this.appUserType : "MAINADMIN");
  }

  setAppAuthData(data) {
    this.appAuthData = data;
    data.map((d: any) => {
      if (d.AuthenticationFor.toUpperCase() === "GOOGLE") {
        sessionStorage.setItem("GOOGLE", JSON.stringify({
          ClientId: d.ClientId
        }));
      }
      if (d.AuthenticationFor.toUpperCase() === "MICRO") {
        sessionStorage.setItem("MICRO", JSON.stringify({
          ClientId: d.ClientId,
          RedirectURL: d.RedirectURL
        }));
      }
      if (d.AuthenticationFor.toUpperCase() === "CAPTCHAKEY") {
        sessionStorage.setItem("CAPTCHAKEY", JSON.stringify({
          CaptchaKey: d.ClientId
        }));
      }
    });
  };

  setUserData(data) {
    this.user = data;
  };
  getUserData() {
    return (this.user ? this.user : null)
  }

  setOrgID(ID) {
    this.orgId = ID;
  };
  getOrgID() {
    return (this.orgId ? this.orgId : null)
  }

  setselectedUserToMove(data) {
    this.selectedUserToMove = data;
  };
  getselectedUserToMove() {
    return (this.selectedUserToMove ? this.selectedUserToMove : null)
  }

  setLoggedInUserSessionId(userId: any) {
    this.loggedInUserSessionId = userId;
    localStorage.setItem("loggedInUserSessionId", JSON.stringify(this.loggedInUserSessionId));
  }

  getLoggedInUserSessionId() {
    if (this.loggedInUserSessionId)
      return this.loggedInUserSessionId;
    else {
      if (localStorage.getItem("loggedInUserSessionId") != null) {
        this.loggedInUserSessionId = JSON.parse(localStorage.getItem("loggedInUserSessionId"));
        return this.loggedInUserSessionId;
      }
      return null;
    }
  }

  setLoggedInUserId(userId: any) {
    this.loggedInUserId = userId;
    localStorage.setItem("loggedInUserId", JSON.stringify(this.loggedInUserId));
  }

  getLoggedInUserId() {
    if (this.loggedInUserId)
      return this.loggedInUserId;
    else {
      if (localStorage.getItem("loggedInUserId") != null) {
        this.loggedInUserId = JSON.parse(localStorage.getItem("loggedInUserId"));
        return this.loggedInUserId;
      }
      return null;
    }
  }

  setLoggedInUserDetails(userDetails: any) {
    this.loggedInUserDetails = userDetails;
    localStorage.setItem("loggedInUserDetails", JSON.stringify(this.loggedInUserDetails));
  }

  getLoggedInUserDetails() {
    if (this.loggedInUserDetails)
      return this.loggedInUserDetails;
    else {
      if (localStorage.getItem("loggedInUserDetails") != null) {
        this.loggedInUserDetails = JSON.parse(localStorage.getItem("loggedInUserDetails"));
        return this.loggedInUserDetails;
      }
      return null;
    }
  }

  setLoggedInUserPermissions(permissions: any) {
    this.loggedInUserPermissions = permissions;
    localStorage.setItem("loggedInUserPermissions", JSON.stringify(this.loggedInUserPermissions));
  }

  getLoggedInUserPermissions() {
    if (this.loggedInUserPermissions)
      return this.loggedInUserPermissions;
    else {
      if (localStorage.getItem("loggedInUserPermissions") != null) {
        this.loggedInUserPermissions = JSON.parse(localStorage.getItem("loggedInUserPermissions"));
        return this.loggedInUserPermissions;
      }
      return null;
    }
  }

  getLoggedInUserModules() {
    let userModulesSet = [];
    let childModulesSet = [];
    let userModules = [];
    this.loggedInUserPermissions = this.getLoggedInUserPermissions();
    if (this.loggedInUserPermissions && this.loggedInUserPermissions.length > 0) {
        this.loggedInUserPermissions.map((permission: any) => {
          let flag = 0;
          if (userModulesSet.length > 0) {
            userModulesSet.map((um: any) => {
              if (um.ModuleName.toUpperCase() === permission.ModuleName.toUpperCase()) {
                flag = 1;
              }
            });
          }
          if (flag === 0) {
            userModulesSet.push({
              ModuleName: permission.ModuleName,
              MainModuleDN: permission.MainModuleDN,
              MainModuleDO: permission.MainModuleDO,
              childModules: []
            });
          }
        });
    }
    userModules = userModulesSet;
    userModules.map((m: any) => {
      childModulesSet = [];
      this.loggedInUserPermissions.map((permission: any) => {
        if (m.ModuleName.toUpperCase() == permission.ModuleName.toUpperCase()) {
          let flag = 0;
          if (childModulesSet.length > 0) {
            childModulesSet.map((um: any) => {
              if (um.ChildModule.toUpperCase() === permission.ChildModule.toUpperCase()) {
                flag = 1;
              }
            });
          }
          if (flag === 0) {
            childModulesSet.push({
              ChildModule: permission.ChildModule,
              DisplayName: permission.DisplayName,
              Permission: permission.Permission,
              Operation: permission.Operation,
              DisplayOrder: permission.DisplayOrder
            });
          }
        }
      });
      m.childModules = childModulesSet;
    });
    return userModules;
  }

  a2b(a) {
    var b, c, d, e = {}, f = 0, g = 0, h = "", i = String.fromCharCode, j = a.length;
    for (b = 0; 64 > b; b++) e["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".charAt(b)] = b;
    for (c = 0; j > c; c++) for (b = e[a.charAt(c)], f = (f << 6) + b, g += 6; g >= 8; ) ((d = 255 & f >>> (g -= 8)) || j - 2 > c) && (h += i(d));
    return h;
  }

  b2a(a) {
    var c, d, e, f, g, h, i, j, o, b = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", k = 0, l = 0, m = "", n = [];
    if (!a) return a;
    do c = a.charCodeAt(k++), d = a.charCodeAt(k++), e = a.charCodeAt(k++), j = c << 16 | d << 8 | e,
    f = 63 & j >> 18, g = 63 & j >> 12, h = 63 & j >> 6, i = 63 & j, n[l++] = b.charAt(f) + b.charAt(g) + b.charAt(h) + b.charAt(i); while (k < a.length);
    return m = n.join(""), o = a.length % 3, (o ? m.slice(0, o - 3) :m) + "===".slice(o || 3);
  }

}
