import {Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { NbDialogService } from "@nebular/theme";

import { Router } from "@angular/router";

import { DialogLogoutConfirmationPromptComponent } from "./logout-confirmation/logout-confirmation-prompt.component";

import { DataService } from "../../../@service/dataService";
import { HttpService } from "../../../@service/httpservice";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { NbIconLibraries } from "@nebular/theme";
import { CustomerFormComponent } from "../../../pages/customers/customers-form/customers-form.component";
import { UpdateUserDetailComponent } from "./update-user-detail/update-user-detail.component";
import { sessionTimeoutService } from "../../../@service/sessionTimeoutService";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  username: any;
  userEmail: any;
  customer: any = [];
  colorCode = "#ffffff!important";
  selectedOrgId: any = null;
  public showStatus: boolean = false;
  public messageStatus: string = "success";
  public alertMessage: string = "";
  public showInActive: boolean = false;
  public appUserType: any = "MAINADMIN";
  public org: any = null;
  customerList: any = [];
  dumyCustomerList: any = [];
  loading = false;
  clientTotalCount: any = 0;
  loggedInId:any;

  pagination: any = {
    offset: 0,
    limit: "500",
    btnCount: 0,
    btnGroup: [],
  };


  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  currentTheme = "default";

  userMenu = [{ title: "Profile" }, { title: "Log out" }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private dialogService: NbDialogService,
    private _router: Router,
    private dataService: DataService,
    private sessionTimeoutService:sessionTimeoutService,
    private httpService: HttpService,
    iconsLibrary: NbIconLibraries,
    private breakpointService: NbMediaBreakpointsService,
  ) {
    this.username = this.dataService.getLoggedInUserDetails().UserName;
    this.userEmail = this.dataService.getLoggedInUserDetails().Email;
    iconsLibrary.registerFontPack("fa", {
      packClass: "fa",
      iconClassPrefix: "fa",
    });
    this.appUserType = this.dataService.getAppUserType();
    if (this.appUserType === "CLIENT") {
      this.org = this.dataService.getOrganisationData();
      let org = JSON.parse(sessionStorage.getItem("clientOrganization"));
      this.colorCode =
        org && org.ColorCode
          ? org.ColorCode + "!important"
          : "#4773b3!important";
    }
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => (this.user = users.nick));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  getLogoSource() {
    if (this.org.Logo && this.org.Logo.length > 0) {
      return this.org.Logo;
    } else {
      return "../../../../assets/images/Logo.png";
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  openLogoutDialog() {
    this.dialogService
      .open(DialogLogoutConfirmationPromptComponent, {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        closeOnEsc: false,
      })
      .onClose.subscribe((param: any) => {
        if (param === "YES") {
          // logout from system
          this.sessionTimeoutService.removeInactivityTracking();
          if (this.dataService.getAppUserType() === "CLIENT") {
            if (window.location.href.indexOf("?") > -1) {
              this._router.navigate([
                "/login/user" +
                  window.location.href.substr(
                    window.location.href.indexOf("?"),
                    window.location.href.length
                  ),
              ]);
            } else {
              this._router.navigate(["/login/user"]);
            }
          } else {
            this._router.navigate(["/login/admin"]);
          }
          // update the logout time of the user session
          this.httpService
            .updateLogoutTime(this.dataService.getAppUserType())
            .subscribe(
              () => {
                localStorage.removeItem("loggedInUserSessionId");
                localStorage.removeItem("loggedInUserId");
                localStorage.removeItem("loggedInUserPermissions");
                localStorage.removeItem("loggedInUserDetails");
                sessionStorage.removeItem("authToken");
                sessionStorage.removeItem("appUserType");
                sessionStorage.removeItem("clientOrganization");
              },
              () => {
                localStorage.removeItem("loggedInUserSessionId");
                localStorage.removeItem("loggedInUserId");
                localStorage.removeItem("loggedInUserPermissions");
                localStorage.removeItem("loggedInUserDetails");
                sessionStorage.removeItem("authToken");
                sessionStorage.removeItem("appUserType");
                sessionStorage.removeItem("clientOrganization");
              }
            );
        }
      });
  }



  openUpdateCustomer() {
    this.loggedInId = this.dataService.getLoggedInUserId();
    const type = "USER";
    this.httpService.getConvertedPass(this.loggedInId, type).subscribe((res) => {
      console.log(res);
      if (Array.isArray(res) && res.length > 0) {
        const customerObj = res[0];
        console.log(customerObj, "@@@@@@@@");

        this.dialogService
          .open(UpdateUserDetailComponent, {
            hasBackdrop: true,
            closeOnBackdropClick: false,
            closeOnEsc: false,
            context: {
              type: "EDIT",
              customerObj: customerObj,
              selectedOrgId: this.loggedInId,
            },
          })

          .onClose.subscribe((param: any) => {
            if (param === "SUCCESS") {
              this.username =
                this.dataService.getLoggedInUserDetails().UserName;
              this.userEmail = this.dataService.getLoggedInUserDetails().Email;
              this.messageStatus = "success";
              this.showStatus = true;
              this.alertMessage = "Customer updated successfully";
              setTimeout(() => {
                this.showStatus = false;
              }, 1000);
              this.loading = true
              
            }
            this.showInActive = false;
          });
      } else {
        console.error("Invalid response format or empty response");
      }
    });
  }

}
