<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon style="color: rgb(255, 255, 255) !important;" icon="menu-2-outline"></nb-icon>
    </a>
    <img *ngIf="appUserType === 'MAINADMIN' || !org" src="../../../../assets/images/Logo.png" style="height: 50px;width: 250px;">
    <img *ngIf="appUserType === 'CLIENT' && org && org.OrganizationLogo !== ''" src="{{org.OrganizationLogo}}" style="height: 50px;">
    <!-- <nb-icon *ngIf="appUserType === 'CLIENT' && org && org.OrganizationLogo === ''" icon="building" pack="fa"></nb-icon>&nbsp;<span *ngIf="org && org.OrganizationName">{{org.OrganizationName}}</span> -->
    <img *ngIf="appUserType === 'CLIENT' && org && org.OrganizationLogo === ''" src="{{getLogoSource()}}" style="height: 50px;">
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>
<div class="header-container" >
  <!-- <label class="userName" style="display: flex;flex-direction: column;color: white;border: 1px solid red;padding:20px 20px;cursor: pointer;">
    <label><nb-icon  icon="person" (click)="openUpdateCustomer()"></nb-icon>&nbsp;{{username}}</label>
    <label style="font-size: 11px;margin:-10px;">{{userEmail}}</label>
  </label> -->
  <div class="user-profile" style="display: flex; flex-direction: column; color: white;"  [style.cursor]="(appUserType === 'CLIENT') ? 'pointer' : 'default'"  (click)="(appUserType === 'CLIENT') ? openUpdateCustomer() : null">
    <div style="display: flex; align-items: center;">
        <nb-icon icon="person">person</nb-icon>
      <span>{{ username }}</span>
    </div>
    <div style="font-size: 11px;">{{ userEmail }}</div>
  </div>
  <nb-actions size="small" [style.color]="colorCode">
    
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action> -->
    <nb-action [style.color]="colorCode"  (click)="openLogoutDialog()" icon="power-outline"></nb-action>
  </nb-actions>
</div>
