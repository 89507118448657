import { Component, Input } from '@angular/core';
import { HttpService } from '../../../../@service/httpservice';
import { DataService } from '../../../../@service/dataService';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbWindowService } from '@nebular/theme';
import { EMAIL_REGEX_VALIDATION } from '../../../../appConstants';

@Component({
  selector: 'ngx-update-user-detail',
  templateUrl: './update-user-detail.component.html',
  styleUrls: ['./update-user-detail.component.scss']
})
export class UpdateUserDetailComponent {
 
  @Input() type: string;
  @Input() customerObj: any;
  @Input() selectedOrgId: any

  showPass: any = false;
  showConfirmPass: any = false;
  urlLocation:any;

  passType: any = "password";
  confirmPassType: any = "password";

  colorCode="#4773b3!important";

  organizationList: any = [];

  public selectedOrganization: any = null;
  public selectedClientRole: any = null;

  public profilePhoto: any = null;
  reportBundleList: any = [];
  public selectReportBundle: any = null;
  showTooltip: boolean = true; 

  // public usersLoginList: any = [];
  public loading = false;
  public showStatus: boolean = false;
  public messageStatus: string = "success";
  public alertMessage: string = "";
  public confirmAndPassNotMatching: boolean = false;

  public clientRolesList: any = [];

  public isApproverLoggedIn = false;
  inValidPass: boolean = false;
  inValidEmail: boolean = false;

  appUserType: any = "MAINADMIN";
  userOrg: any = {};

  public addUser: any = {
    id: null,
    Email: "",
    UserName: "",
    FirstName: "",
    LastName: "",
    Password: "",
    Provider: "SIGNUP",
    UniqueID: null,
    AuthToken: null,
    ConfirmPassword: "",
    id_FkOrganization: null,
    id_ClientUserRole: null,
    id_FkReportBundle:null,
    ContactNo: null,
    OtherDetails: "",
    ProfilePicture: "",
    SendEmail: false
  }

  isAdminRole: any = false;

  constructor(
    protected ref: NbDialogRef<UpdateUserDetailComponent>,
    private httpService: HttpService,
    private dataService: DataService,
    private router:Router) {
      this.appUserType = this.dataService.getAppUserType();
      if (this.appUserType === "CLIENT") {
        this.userOrg = this.dataService.getOrganisationData();
        this.selectedOrgId = this.userOrg.OrgId;
        this.getClientRoles(this.selectedOrgId);
        this.getReportBundleList()
        let org = JSON.parse(sessionStorage.getItem('clientOrganization'));
        this.colorCode = org && org.ColorCode ? org.ColorCode + "!important" : "#4773b3!important";
      } else {
      }
  }


  getClientRoles(orgId: any) {
    this.loading = true;
    this.httpService.getClientRoles(orgId, 'FILTER').subscribe((res: any) =>{
      this.clientRolesList = res;
      this.loading = false;
      if (this.addUser.id_ClientUserRole && this.addUser.id_ClientUserRole != null) {
        this.clientRolesList.map((c: any) => {
          if (c.id === this.addUser.id_ClientUserRole) {
            this.selectedClientRole = c;
          }
        });
      }
      // alocate Reader role by default -- this is for phase 1 implementation of Benson
      // this.clientRolesList.map((c: any) => {
      //   if (c.ClientUserRole === "Reader") {
      //     this.selectedClientRole = c;
      //     this.addUser.id_ClientUserRole = this.selectedClientRole.id;
      //   }
      // })
    },(res : any) =>{
      this.loading = false;
      this.messageStatus = "danger";
      this.showStatus = true;
      this.alertMessage = "Failed to get list of User Roles. Try again.";
      setTimeout(()=>{
        this.showStatus = false;
      },5000);
    });

  }

  getConvertedPass() {
    if (this.type === "EDIT" && this.customerObj.id !== "") {
      this.httpService.getConvertedPass(this.customerObj.id, "USER").subscribe((data: any) => {
        if (data && data.length > 0) {
          this.addUser.Password = data[0].convertedPass;
          this.addUser.ConfirmPassword = data[0].convertedPass;
          this.isAdminRole = data[0].ClientUserRole === 'Admin' ? true : false;

        } else {
          this.addUser.Password = "";
          this.isAdminRole = false;
        }
      });
    } else {
      this.isAdminRole = false;
    }
  }

  ngOnInit() {
    this.urlLocation = this.router.url;
    if (this.type === "EDIT") {
      this.addUser = {
        id: this.customerObj.id,
        Email: this.customerObj.Email,
        UserName: this.customerObj.UserName,
        FirstName: this.customerObj.UserName.substring(0, this.customerObj.UserName.indexOf(' ')),
        LastName: this.customerObj.UserName.substring(this.customerObj.UserName.indexOf(' ') + 1, this.customerObj.UserName.length),
        Password: this.customerObj.Password,
        Provider: this.customerObj.Provider,
        UniqueID: this.customerObj.UniqueID ? this.customerObj.UniqueID : '',
        AuthToken: this.customerObj.AuthToken ? this.customerObj.AuthToken : '',
        ConfirmPassword: this.customerObj.Password,
        id_FkOrganization: this.customerObj.id_FkOrganization,
        id_FkReportBundle:this.customerObj.id_FkReportBundle,
        id_ClientUserRole: this.customerObj.id_ClientUserRole,
        ContactNo: this.customerObj.ContactNo,
        OtherDetails: this.customerObj.OtherDetails ? this.customerObj.OtherDetails : "",
        ProfilePicture: this.customerObj.ProfilePicture ? this.customerObj.ProfilePicture : "",
        SendEmail: (this.customerObj.SendEmail && (this.customerObj.SendEmail == 1 || this.customerObj.SendEmail === true)) ? true : false
      }
      this.getConvertedPass();
      this.customerObj.id_FkOrganization && this.getClientRoles(this.customerObj.id_FkOrganization);
      if (this.customerObj.ProfilePicture && this.customerObj.ProfilePicture !== null) {
        this.profilePhoto = this.customerObj.ProfilePicture;
      }
    } else {
      this.addUser = {
        id: null,
        Email: "",
        UserName: "",
        Password: "",
        Provider: "SIGNUP",
        UniqueID: "",
        AuthToken: "",
        FirstName: "",
        LastName: "",
        ConfirmPassword: "",
        id_FkOrganization: this.selectedOrgId,
        id_FkReportBundle:null,
        ContactNo: null,
        OtherDetails: "",
        ProfilePicture: "",
        id_ClientUserRole: null,
        SendEmail: false
      }
    }
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    this.ref.close();
  }

  addUpdateCustomer() {
    // let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    this.addUser.UserName = this.addUser.FirstName + " "  + this.addUser.LastName;
    // if (!this.addUser.Email.match(regexEmail)) {
    //   this.messageStatus = "danger";
    //   this.showStatus = true;
    //   this.alertMessage = "Invalid mail id";
    //   setTimeout(()=>{
    //     this.showStatus = false;
    //   },5000);
    //   return;
    // }
    if (this.addUser.Password !== this.addUser.ConfirmPassword) {
      this.messageStatus = "danger";
      this.showStatus = true;
      this.alertMessage = "Password and Confirm password are not matching.";
      setTimeout(()=>{
        this.showStatus = false;
      },5000);
      return;
    }
    this.httpService.registerUser(this.addUser).subscribe((data: any) => {
      if (data && data.status == "200") {
        let loggedInUser = this.dataService.getLoggedInUserDetails();
        loggedInUser.UserName = this.addUser.UserName;
        this.dataService.setLoggedInUserDetails(loggedInUser);
        this.messageStatus = "success";
        this.showStatus = true;
        this.alertMessage = data.message;
        this.router
            .navigateByUrl("/", {
              skipLocationChange: true,
            })
            .then(() => {
              this.router.navigate([this.urlLocation]);
            });
        this.ref.close('SUCCESS');
      } else {
        this.messageStatus = "danger";
        this.showStatus = true;
        this.alertMessage = data.message;
        setTimeout(()=> {
          this.showStatus = false;
        }, 3500);
      }
    }, (error: any)=> {
        this.messageStatus = "danger";
        this.showStatus = true;
        this.alertMessage = error.message;
        setTimeout(()=> {
          this.showStatus = false;
        }, 3500);
    });
  }

  validatePassword() {
    let regexPass = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g;
    if (this.addUser.Password.length > 0) {
      if (!this.addUser.Password.match(regexPass)) {
        this.inValidPass = true;
      } else {
        this.inValidPass = false;
      }
    } else {
      this.inValidPass = false;
    }
  }

  compareConfirmPassword() {
    this.confirmAndPassNotMatching = false;
    if (this.addUser.ConfirmPassword.length > 0 && this.addUser.Password !== this.addUser.ConfirmPassword) {
      this.confirmAndPassNotMatching = true;
    }
  }

  // onOrgSelected(orgId: any) {
  //   this.addUser.id_FkOrganization = orgId;
  //   this.getClientRoles(orgId);
  // }

  // onRoleSelected(roleId: any) {
  //   this.addUser.id_ClientUserRole = roleId;
  // }

  removeFile() {
    this.profilePhoto = null;
    this.addUser.ProfilePicture = "";
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.profilePhoto = event.target.files[0];
      if (Math.round((this.profilePhoto.size/1024)) > 100) {
        this.messageStatus = "danger";
        this.showStatus = true;
        this.alertMessage = "File is too large, it must be less than 100 KB.";
        this.profilePhoto = null;
      } else {
        this.showStatus = false;
        this.alertMessage = "";
        const reader = new FileReader();

        reader.onload = (e) => { // called once readAsDataURL is completed
          this.profilePhoto = e.target.result;
          this.addUser.ProfilePicture = e.target.result;
        }
      }
    }
  }

  getReportBundleList(){
    this.loading = true;
    this.reportBundleList = [];
    this.httpService.getReportBundleListPage("0","100","false").subscribe((res: any) =>{
      // this.reportBundleList = res.list
      res?.list.map((c: any) => {
        if (c.IsActive) {
          this.reportBundleList.push(c);
          if (this.customerObj && this.customerObj.id_FkReportBundle === c.id) {
            this.selectReportBundle = c;
          }
        }
      });
      this.loading = false;
    },(res : any) =>{
      this.loading = false;
      this.messageStatus = "danger";
      this.showStatus = true;
      this.alertMessage = "Failed to get list of reportBundle. Try again.";
      setTimeout(()=>{
        this.showStatus = false;
      },5000);
    });
  }

  getSorteReportBundleList() {
    let report = [];
    this.reportBundleList.sort((a,b) => (a.GroupName.toLowerCase() > b.GroupName.toLowerCase()) ? 1 : ((b.GroupName.toLowerCase() > a.GroupName.toLowerCase()) ? -1 : 0));

    this.reportBundleList.map((o: any) => {
      if (o.id !== 'ALL') {
        report.push(o,);
      }
    });

    return report;
  }

  onOrgreportSelected(reportId: any) {
    this.addUser.id_FkReportBundle = reportId;
    // this.getClientRoles(report);
  }

  setSelectedReportBundle(reportBundle:any) {
    if (reportBundle) {
      this.showTooltip = false;
    }
    const selectedValue ={value:reportBundle}
    if (selectedValue && selectedValue.value && selectedValue?.value?.GroupName?.length > 0) {
      this.selectReportBundle = selectedValue.value;
      this.onOrgreportSelected(this.selectReportBundle.id);
    } else {
      this.selectReportBundle = null;
      this.addUser.id_FkReportBundle = null;
    }
    
    // if (reportBundle && reportBundle.value && reportBundle?.value?.GroupName?.length > 0) {
    //   this.selectReportBundle = reportBundle.value;
    //   console.log(this.selectReportBundle,"report bundle")
    //   this.onOrgreportSelected(this.selectReportBundle.id);
    // } else {
    //   this.selectReportBundle = null;
    //   // this.selectedClientRole = null;
    //   this.addUser.id_FkReportBundle = null;
    //   // this.addUser.id_ClientUserRole = null;
    // }
  }

}
