<nb-card>
    <nb-card-header>Update User</nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label><span style="color: red;">*</span> First Name</label>
            <input type="text" [(ngModel)]="addUser.FirstName" nbInput fullWidth placeholder="First Name"  >
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label><span style="color: red;">*</span> Last Name</label>
            <input type="text" [(ngModel)]="addUser.LastName" nbInput fullWidth placeholder="Last Name" >
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label><span style="color: red;">*</span> Email</label>
            <input type="email" [(ngModel)]="addUser.Email" nbInput fullWidth placeholder="Email" readonly >
            <label *ngIf="inValidEmail" style="color: red;font-size: 12px;">Invalid Email Id, please enter valid Email Address.</label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label> Contact No</label>
            <input type="text" [(ngModel)]="addUser.ContactNo"  nbInput fullWidth placeholder="Contact No" maxlength="12">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label style="display: block;"><span style="color: red;">*</span> Organization</label>
            <!-- <nb-select *ngIf="appUserType === 'MAINADMIN'" placeholder="Select Organization" style="width: 100%;" [selected]="addUser.id_FkOrganization" (selectedChange)='onOrgSelected($event)'>
              <nb-option *ngFor="let org of organizationList" [value]="org.id">{{org.OrganizationName}}</nb-option>
            </nb-select> -->
            <ngx-select-dropdown *ngIf="organizationList.length > 0 && appUserType === 'MAINADMIN'"
              [config]="organizationConfig"
              [options]="getSortedOrgList()"
              (change)="setSelectedOrganization($event)"
              [(ngModel)]="selectedOrganization" ></ngx-select-dropdown>
            <label class="readOnlyOrg" *ngIf="appUserType === 'CLIENT'">{{userOrg.OrganizationName}}</label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label style="display: block;"><span style="color: red;">*</span> User Role</label>
            <ngx-select-dropdown *ngIf="clientRolesList.length > 0 && !isAdminRole"
              [config]="clientRolesConfig"
              [disabled]="!addUser.id_FkOrganization"
              (change)="setSelectedClientRole($event)"
              [(ngModel)]="selectedClientRole" ></ngx-select-dropdown>
              <input  *ngIf="!isAdminRole" type="text" value="Reader"   nbInput fullWidth readonly>
              <input  *ngIf="isAdminRole" type="text" value="Admin"   nbInput fullWidth readonly>

            <!-- <label *ngIf="isAdminRole">Admin</label> -->
            <!-- <label>
              <span *ngIf="selectedClientRole && selectedClientRole.ClientUserRole">{{selectedClientRole.ClientUserRole}}</span>
              <span *ngIf="!selectedClientRole || !selectedClientRole.ClientUserRole || selectedClientRole.ClientUserRole === ''">-</span>
            </label> -->
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label><span style="color: red;">*</span>Password</label>
            <div style="display: flex;position: relative;">
              <input style="padding-right: 40px;" [type]="passType" [(ngModel)]="addUser.Password" nbInput fullWidth placeholder="Password">
              <nb-icon [style.color]="colorCode" *ngIf="!customerObj.id && showPass" class="showPassIcon" icon="eye" (click)="showPassword()"></nb-icon>
              <nb-icon [style.color]="colorCode" *ngIf="!customerObj.id && !showPass" class="showPassIcon" icon="eye-off" (click)="showPassword()"></nb-icon>
            </div>
            <label *ngIf="inValidPass" style="color: red;font-size: 12px;">Password must be minimum of 8 characters should contain lowercase letter, capital letter and numbers.</label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label><span style="color: red;">*</span> Confirm Password</label>
            <div style="display: flex;position: relative;">
              <input style="padding-right: 40px;" [type]="confirmPassType" [(ngModel)]="addUser.ConfirmPassword"  (ngModelChange)="compareConfirmPassword()" nbInput fullWidth placeholder="Confirm Password">
              <nb-icon [style.color]="colorCode" *ngIf="!customerObj.id && showConfirmPass" class="showPassIcon" icon="eye" (click)="showConfirmPassword()"></nb-icon>
              <nb-icon [style.color]="colorCode" *ngIf="!customerObj.id && !showConfirmPass" class="showPassIcon" icon="eye-off" (click)="showConfirmPassword()"></nb-icon>
            </div>
            <label *ngIf="confirmAndPassNotMatching" style="color: red;font-size: 12px;">Password and Confirm password are not matching...</label>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <label style="display: block"
              ><span style="color: red">*</span>Report Bundle</label
            >
            <!-- <nb-select *ngIf="appUserType === 'MAINADMIN'" placeholder="Select Organization" style="width: 100%;" [selected]="addUser.id_FkOrganization" (selectedChange)='onOrgSelected($event)'>
              <nb-option *ngFor="let org of organizationList" [value]="org.id">{{org.OrganizationName}}</nb-option>
            </nb-select> -->
            <!-- <ngx-select-dropdown  *ngIf="reportBundleList.length > 0 && appUserType === 'MAINADMIN'"
              [config]="reportBundleConfig"
              [options]="getSorteReportBundleList()"
              (change)="setSelectedReportBundle($event)"
              [(ngModel)]="selectReportBundle"
             ></ngx-select-dropdown> -->
             
            <nb-select
                          style="width: 100%"
              placeholder="Select report bundle"
              *ngIf="
                reportBundleList.length > 0 && isAdminRole
              "
              [(ngModel)]="selectReportBundle"
              (ngModelChange)="setSelectedReportBundle($event)"
              (mouseover)="showTooltip = true"
              (mouseleave)="showTooltip = false"
            >
              <nb-option
                *ngFor="let bundle of getSorteReportBundleList()"
                [value]="bundle"
                nbTooltip="{{ bundle.GroupDescription }}"
                [nbTooltipDisabled]="showTooltip"
                >{{ bundle.GroupName }}</nb-option
              >
            </nb-select>
            <label class="readOnlyOrg" *ngIf="!isAdminRole">{{selectReportBundle.GroupName}}</label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Other Details</label>
            <textarea [(ngModel)]="addUser.OtherDetails" nbInput fullWidth placeholder="Other Details" maxlength="500"></textarea>
            <!-- <nb-checkbox [checked]="addUser.SendEmail" [(ngModel)]="addUser.SendEmail" style="width: 100%;position: relative; top: 25px;">Allow to send Email Alerts</nb-checkbox> -->
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Profile Picture</label>
            <div class="empty-image-container">
              <img *ngIf="profilePhoto" src="{{profilePhoto}}" [src]="profilePhoto" style="height: 100px;width: 100px">
            </div>
            <label class="btn btn-primary" [style.background-color]="colorCode" [style.border-color]="colorCode" style="color: white;" *ngIf="!profilePhoto">
              Browse&hellip; <input type="file" style="display: none;" (change)="onSelectFile($event)" accept="image/gif, image/jpeg, image/jpg, image/png">
            </label>
            <label *ngIf="profilePhoto" class="btn btn-danger" [style.background-color]="colorCode" [style.border-color]="colorCode" style="margin-top: 10px;" (click)="removeFile()">
              Remove
            </label>
          </div>
        </div>
        <div class="col-sm-12">
          <nb-alert *ngIf="showStatus === true" status="{{messageStatus}}" class="alert-message">{{alertMessage}}</nb-alert>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="floatRight">
        <button class="cancel" nbButton status="danger" (click)="cancel()">Cancel</button>
        <button  nbButton status="success" (click)="addUpdateCustomer()" [disabled]="!addUser.id_FkOrganization || !addUser.id_FkReportBundle || !addUser.FirstName || !addUser.LastName || !addUser.Email || inValidEmail || !addUser.Password || !addUser.ConfirmPassword || inValidPass || confirmAndPassNotMatching">Submit</button>
      </div>
    </nb-card-footer>
  </nb-card>
  